import "./Button.css"

export default function Button({name, SVGpicture, onClick}) {

    return (
        <div className="button-top-container clickableOpacity" onClick={onClick}>
            <div className="button-contents-wrapper">
                <SVGpicture className="button-svg" />
                <p className="button-name">{name}</p>
            </div>
        </div>
    );
}
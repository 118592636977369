export default function FolderIcon({ className, color }) {
	return (
		<svg
			className={className}
			width="32"
			height="26"
			viewBox="0 0 32 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.2 25.9334C2.32 25.9334 1.56693 25.6203 0.9408 24.9942C0.3136 24.367 0 23.6134 0 22.7334V3.53337C0 2.65337 0.3136 1.90031 0.9408 1.27417C1.56693 0.646974 2.32 0.333374 3.2 0.333374H12.8L16 3.53337H28.8C29.68 3.53337 30.4336 3.84697 31.0608 4.47417C31.6869 5.10031 32 5.85337 32 6.73337V22.7334C32 23.6134 31.6869 24.367 31.0608 24.9942C30.4336 25.6203 29.68 25.9334 28.8 25.9334H3.2Z"
				fill={color ? color : "white"}
			/>
		</svg>
	);
}

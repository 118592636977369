import { useContext, useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export const useAuth = () => {

    const context = useContext(AuthContext);
    const navigate = useNavigate();

    const {loggedIn} = context;

    if(!context) throw new Error("useAuth must be used within an AuthProvider");

    useEffect(() => {
        localStorage.setItem("loggedIn", loggedIn);
        if(!loggedIn) navigate("/");
    }, [loggedIn, navigate])

    return context;
}
import FolderIcon from "./FolderIcon.jsx";
import IMGIcon from "./IMGIcon.jsx";
import PDFIcon from "./PDFIcon.jsx";

const fileIconsProvider = {
    "folder" : FolderIcon,
    "img" : IMGIcon,
    "pdf" : PDFIcon,
}

export default fileIconsProvider;
import "./ShipList.css";
import shipList from "../../../../json/ships.json";

import { useNavigate } from "react-router-dom";


import NavBar from '../../Components/NavBar/NavBar.jsx';
import ShipCard from "./Components/ShipCard/ShipCard.jsx";
import NewShipCard from "./Components/NewShipCard/NewShipCard.jsx";


export default function Shiplist() {

    const navigate = useNavigate();

    return (
        <div id="shipListPageTopContainer">
            <NavBar subDirectory={[]}/>
            <div className="nav-replacer" />
            <div id="shipGrid">
                {shipList.map((ship, index) => (
                    <ShipCard onClick={() => navigate("ship/"+ship.imo)} ship={ship} key={index}/>
                ))}
                <NewShipCard />
            </div>
        </div>
    );
}
import "./FolderButton.css"

import fileIconsProvider from "../../../../../../../../img/SVGComponents/FileIconsProvider.js";

export default function FolderButton({name, onClick, className}) {

    const FolderIcon = fileIconsProvider["folder"];

    return (
        <button className={"folderButton-top-container clickableOpacity " + className} onClick={onClick}>
            <FolderIcon className="folder-icon" />
            <p className="folderButton-name">{name.length > 15 ? name.substring(0,11) + "..." : name}</p>
        </button>
    );
}
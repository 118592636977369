import "./UploadButton.css";


export default function UploadButton({onClick}) {

    return (

        <button id="shared-files-upload-button" className="clickableBrightness">

            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 10.1281C6.75208 10.1281 6.54442 10.0441 6.377 9.87612C6.209 9.70871 6.125 9.50104 6.125 9.25313V2.99687L4.48438 4.6375C4.30937 4.8125 4.10521 4.9 3.87188 4.9C3.63854 4.9 3.42708 4.80521 3.2375 4.61562C3.0625 4.44062 2.97879 4.23267 2.98638 3.99175C2.99338 3.75142 3.07708 3.55104 3.2375 3.39062L6.3875 0.240625C6.475 0.153125 6.56979 0.0909998 6.67188 0.0542498C6.77396 0.0180832 6.88333 0 7 0C7.11667 0 7.22604 0.0180832 7.32812 0.0542498C7.43021 0.0909998 7.525 0.153125 7.6125 0.240625L10.7625 3.39062C10.9375 3.56562 11.0212 3.77329 11.0136 4.01363C11.0066 4.25454 10.9229 4.45521 10.7625 4.61562C10.5875 4.79062 10.3798 4.88162 10.1395 4.88863C9.89858 4.89621 9.69063 4.8125 9.51562 4.6375L7.875 2.99687V9.25313C7.875 9.50104 7.79129 9.70871 7.62388 9.87612C7.45588 10.0441 7.24792 10.1281 7 10.1281ZM1.75 13.6281C1.26875 13.6281 0.856917 13.4569 0.5145 13.1145C0.1715 12.7715 0 12.3594 0 11.8781V10.1281C0 9.88021 0.0837085 9.67225 0.251125 9.50425C0.419125 9.33683 0.627083 9.25313 0.875 9.25313C1.12292 9.25313 1.33087 9.33683 1.49887 9.50425C1.66629 9.67225 1.75 9.88021 1.75 10.1281V11.8781H12.25V10.1281C12.25 9.88021 12.334 9.67225 12.502 9.50425C12.6694 9.33683 12.8771 9.25313 13.125 9.25313C13.3729 9.25313 13.5806 9.33683 13.748 9.50425C13.916 9.67225 14 9.88021 14 10.1281V11.8781C14 12.3594 13.8288 12.7715 13.4864 13.1145C13.1434 13.4569 12.7312 13.6281 12.25 13.6281H1.75Z" fill="white"/>
            </svg>

            <span>Upload</span>


        </button>

    );

}
export default function IMGIcon({ className, color }) {
	return (
		<svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.55556 32C2.57778 32 1.74044 31.6521 1.04356 30.9564C0.347852 30.2596 0 29.4222 0 28.4444V3.55556C0 2.57778 0.347852 1.74044 1.04356 1.04356C1.74044 0.347852 2.57778 0 3.55556 0H28.4444C29.4222 0 30.2596 0.347852 30.9564 1.04356C31.6521 1.74044 32 2.57778 32 3.55556V28.4444C32 29.4222 31.6521 30.2596 30.9564 30.9564C30.2596 31.6521 29.4222 32 28.4444 32H3.55556ZM5.33333 24.8889H26.6667L20 16L14.6667 23.1111L10.6667 17.7778L5.33333 24.8889Z"
				fill={color ? color : "white"}
			/>
		</svg>
	);
}

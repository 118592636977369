import "./ShipDashboard.css";
import shipList from "../../../../json/ships.json";

import { useEffect } from "react";
import { Routes, Route, useParams } from "react-router-dom";

import NavBar from "../../Components/NavBar/NavBar";
import DashboardBanner from "./Components/DashboardBanner/DashboardBanner.jsx";
import Buttons from "./Components/Buttons/Buttons.jsx";
import LatestUpdates from "./Components/LatestUpdates/LatestUpdates.jsx";
import ChatButton from "./Components/ChatButton/ChatButton.jsx";
import Error404 from "../../../Error/404/Error404.jsx";

import ShipInfo from "./Pages/ShipInfo/ShipInfo.jsx";
import Issues from "./Pages/Issues/Issues";
import SharedFiles from "./Pages/SharedFiles/SharedFiles";
import Gallery from "./Pages/Gallery/Gallery";
import CrewList from "./Pages/CrewList/CrewList";

function Dashboard({ ship }) {

	useEffect(() => {

		if (!ship || !ship.name) return;

		document.title = ship.name + " - ShipManager";

		return () => {
			document.title = "ShipManager";
		}

	}, [ship]);

	return (
		<div id="shipDashboardTopContainer">
			<NavBar subDirectory={[{text: `${ship.name}`, href: "."}]} />
			<div className="nav-replacer" />
			<DashboardBanner ship={ship} />
			<div id="shipDashboard-grid">
				<Buttons />
				<LatestUpdates />
			</div>
			<ChatButton ship={ship}/>
			
		</div>
	);

}

export default function ShipDashboard() {

	const params = useParams();

	const ship = shipList.find((ship) => ship.imo === params.imo);



	if(!ship) return <Error404 />

	return (
		<Routes>
			<Route path="/" element={<Dashboard ship={ship} />} />
			<Route path="/info" element={<ShipInfo ship={ship} />} />
			<Route path="/issues" element={<Issues ship={ship}/>} />
			<Route path="/files" element={<SharedFiles ship={ship}/>} />
			<Route path="/gallery" element={<Gallery ship={ship} />} />
			<Route path="/crew" element={<CrewList ship={ship} />} />
			<Route path="*" element={<Error404 />} />
		</Routes>
	);
}

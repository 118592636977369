export default function FolderTraceIcon({className}) {
	return (
		<svg
			className={className}
			width="37"
			height="29"
			viewBox="0 0 37 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.16667 28.8333C3.18125 28.8333 2.33797 28.4828 1.63683 27.7816C0.934499 27.0793 0.583332 26.2354 0.583332 25.25V3.74999C0.583332 2.76457 0.934499 1.9213 1.63683 1.22016C2.33797 0.517823 3.18125 0.166656 4.16667 0.166656H14.9167L18.5 3.74999H32.8333C33.8187 3.74999 34.6626 4.10116 35.365 4.80349C36.0661 5.50463 36.4167 6.34791 36.4167 7.33332V25.25C36.4167 26.2354 36.0661 27.0793 35.365 27.7816C34.6626 28.4828 33.8187 28.8333 32.8333 28.8333H4.16667ZM4.16667 3.74999V25.25H32.8333V7.33332H17.0219L13.4385 3.74999H4.16667ZM4.16667 3.74999V25.25V3.74999Z"
				fill="black"
			/>
		</svg>
	);
}

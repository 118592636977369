import "./Main.css";

import Shiplist from "./Pages/ShipList/ShipList.jsx";

export default function Home() {

    return (
        <Shiplist />
    );

}
import "./ShipInfo.css"

import NavBar from "../../../../Components/NavBar/NavBar.jsx";
import Error404 from "../../../../../Error/404/Error404.jsx";

export default function ShipInfo({ship, setLoggedIn}) {

    if (!ship.details) return <Error404 />;

    const titleCase = (s) => s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())

    return (
        <div id="shipInfo-container">
            <NavBar subDirectory={[{text: `${ship.name}`, href: ".."}, {text: "Ship Info", href: "."}]} setLoggedIn={setLoggedIn}/>
            <div className="nav-replacer" />

            <div id="shipInfo-card">
                <div id="shipInfo-card-header">
                    <div id="shipInfo-card-header-wrapper">
                        <div id="shipInfo-card-header-image" style={{backgroundImage: `url(${ship.img})`}} />
                        <div id="shipInfo-card-header-text">
                            <h1>{ship.name}</h1>
                            <p>{ship.details.general.type} | IMO: {ship.imo}</p>
                        </div>
                    </div>
                </div>
                

                {Object.keys(ship.details).map((key1, index1) => (
                    <div key={key1} id={"shipInfo-card-"+key1} className="shipInfo-card-section">
                    <h3>{titleCase(key1)} Information:</h3>
                    <div className="shipInfo-card-section-wrapper">
                        
                    {Object.keys(ship.details[key1]).map((key2, index2) => (
                        <p key={key2}>
                            <span className="shipInfo-card-section-key">{(key2 !== "imo" ? titleCase(key2) : "IMO") + ": "}</span>
                            <span className="shipInfo-card-section-value">{ship.details[key1][key2]}</span>
                        </p>
                    ))
                    }
                    </div>
                </div>
                ))}

            </div>
        </div>
    );

}
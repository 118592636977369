export default function PDFIcon({ className, color }) {
	return (
		<svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M28.4444 0H3.55556C1.6 0 0 1.6 0 3.55556V28.4444C0 30.4 1.6 32 3.55556 32H28.4444C30.4 32 32 30.4 32 28.4444V3.55556C32 1.6 30.4 0 28.4444 0ZM11.5556 15.1111C11.5556 16.5333 10.3111 17.7778 8.88889 17.7778H7.11111V21.3333H4.44444V10.6667H8.88889C10.3111 10.6667 11.5556 11.9111 11.5556 13.3333V15.1111ZM20.4444 18.6667C20.4444 20.0889 19.2 21.3333 17.7778 21.3333H13.3333V10.6667H17.7778C19.2 10.6667 20.4444 11.9111 20.4444 13.3333V18.6667ZM27.5556 13.3333H24.8889V15.1111H27.5556V17.7778H24.8889V21.3333H22.2222V10.6667H27.5556V13.3333ZM16 13.3333H17.7778V18.6667H16V13.3333ZM7.11111 13.3333H8.88889V15.1111H7.11111V13.3333Z"
				fill={color ? color : "white"}
			/>
		</svg>
	);
}

import "./NavBar.css";

import { useNavigate } from "react-router-dom";
import {MdNotificationsNone, MdOutlineAccountCircle} from "react-icons/md";

import BackChevronIcon from "../../../../img/SVGComponents/BackChevron";

import { useAuth } from "../../../../hooks/useAuth";

export default function NavBar({subDirectory}) {

    const { setLoggedIn } = useAuth();
    const navigate = useNavigate();

    return (
        <nav className="nav-bar">
            <div className="nav-left-expand">
                <h2 className="nav-title">
                    {subDirectory.length ?
                    <p className="nav-shipmanager-a" onClick={() => navigate("/")}>ShipManager</p>
                     :
                     "ShipManager"}
                    {subDirectory &&
                        subDirectory.map((subDir, index) => (
                            <span key={index} className={"nav-subdirectory-container " + (index !== subDirectory.length-1 ? "clickableBrightness" : "")}>
                                {" / "} <span className="nav-subdirectory" onClick={() => navigate(subDir.href)}>{subDir.text}</span>
                            </span>
                        ))
                    }
                </h2>
                
            </div>

            <div className="nav-left-shrink">
                {subDirectory.length ?
                    <h2 className="nav-title">
                        <p className="nav-arrow-a" onClick={() => subDirectory.length === 1 ? navigate("/") : navigate("..")}>
                        <BackChevronIcon />
                        
                        </p>
                        <span className="nav-subdirectory-container">
                            <span className="nav-subdirectory">
                                {subDirectory[subDirectory.length - 1].text}
                            </span>
                        </span>
                    </h2>
                    
                :
                    <h2 className="nav-title">
                        <p className="nav-shipmanager-a" onClick={() => navigate("/")}>ShipManager</p>
                    </h2>
                }
            </div>
            <div className="nav-right">
                <MdNotificationsNone className="nav-icon" fontSize={30} color="white"/>
                <MdOutlineAccountCircle className="nav-icon" fontSize={30} color="white" onClick={() => setLoggedIn(false)}/>
            </div>
        </nav>
    );

}
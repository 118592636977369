import "./IssueCard.css";

const severityName = ['Low', 'Medium', 'High', 'Crucial']
const severityColors = ['white', 'orange', 'red', 'darkred']

export default function IssueCard({issue, view}) {

    return (
        <div className="issueCard-container">
            
            <div className="issueCard-date">
                {issue.date.toLocaleDateString("en-US",{weekday: "long", year: "numeric", month: "long", day: "numeric"})}
            </div>

            <div className="issueCard-main-container">

                <h1 className="issueCard-main-title">
                    {issue.title}
                </h1>
                <p className="issueCard-main-subInfo">
                    Vessel: {issue.vessel}<br />
                    Posted by: {issue.postedBy.name} ({issue.postedBy.role})<br />
                </p>
                <p className="issueCard-main-severity">
                    Severity: <span style={{color: severityColors[issue.severity]}}>{severityName[issue.severity]}</span>
                </p>
            </div>

            <button className="issueCard-view-button clickableBrightness" onClick={view}>
                View
            </button>

        </div>

    );

}
import "./ShipCard.css";

import {MdMoreVert} from "react-icons/md";

export default function ShipCard({ship, onClick}) {

    return (
        <div className="ship-card card-button" onClick={onClick}>
            <div className="ship-card-img" style={{backgroundImage: `url(${ship.img})`}}/>
            <div className="ship-card-info">
                <div className="ship-card-info-text">
                    <h3>{ship.name}</h3>
                    <p>IMO: {ship.imo}</p>
                </div>

                <MdMoreVert className="ship-card-more-button" fontSize={24} />
            </div>
        </div>
    );

}
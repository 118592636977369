import { useNavigate } from "react-router-dom";
import "./Error404.css";

export default function Error404() {

    const navigate = useNavigate();

    return (
        <div id="error404">
            <h1>404</h1>
            <h2>Page not found</h2>
            <button onClick={() => navigate("/")} >Back to home</button>
        </div>
    );
}
import "./Login.css";

import { useAuth } from "../../hooks/useAuth";

export default function Login() {

    const { setLoggedIn } = useAuth();

    const formSubmit = (e) => {

        e.preventDefault();

        setLoggedIn(true);

    }

    return (
        <div id="logInPageTopContainer">

            <div id="logInFormContainer">
                <form onSubmit={(e) => formSubmit(e)}>
                    <h1>ShipManager</h1>
                    <input type="username" placeholder="username" required={true} autoFocus={true}/>
                    <input type="password"  placeholder="password" required={true}/>
                    <button type="submit" className="clickableOpacity">Sign In</button>
                </form>
            </div>

        </div>
    );
    
}
import { createContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const [loggedIn, setLoggedIn] = useState(localStorage.getItem("loggedIn") === "true" ? true : false);
    
    return (
        <AuthContext.Provider value={{ loggedIn, setLoggedIn }}>
        {children}
        </AuthContext.Provider>
    );

}
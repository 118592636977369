import "./SearchButton.css";


export default function SearchButton({onClick}) {

    return (

        <button id="crewlist-search-button" className="clickableBrightness" onClick={onClick}>

            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.2916 9.0566H9.64117L9.41063 8.8343C10.2454 7.86609 10.7042 6.63001 10.7033 5.35163C10.7033 4.29318 10.3894 3.2585 9.80135 2.37843C9.2133 1.49835 8.37749 0.812423 7.39961 0.407371C6.42173 0.00231878 5.34569 -0.103661 4.30758 0.102832C3.26947 0.309326 2.3159 0.819019 1.56746 1.56746C0.819019 2.3159 0.309326 3.26947 0.102832 4.30758C-0.103661 5.34569 0.00231878 6.42173 0.407371 7.39961C0.812423 8.37749 1.49835 9.2133 2.37843 9.80135C3.2585 10.3894 4.29318 10.7033 5.35163 10.7033C6.67719 10.7033 7.89571 10.2175 8.8343 9.41063L9.0566 9.64117V10.2916L13.1732 14.4L14.4 13.1732L10.2916 9.0566ZM5.35163 9.0566C3.30155 9.0566 1.64666 7.40172 1.64666 5.35163C1.64666 3.30155 3.30155 1.64666 5.35163 1.64666C7.40172 1.64666 9.0566 3.30155 9.0566 5.35163C9.0566 7.40172 7.40172 9.0566 5.35163 9.0566Z" fill="white"/>
            </svg>


            <span>Search</span>

        </button>

    );

}
import "./Issue.css";

import {useState} from "react";

import formatDistanceToNow from "date-fns/formatDistanceToNow";

import PDFIcon from "../../../../../../../../img/SVGComponents/PDFIcon.jsx";
import BackChevronIcon from "../../../../../../../../img/SVGComponents/BackChevron";

const severityName = ['Low', 'Medium', 'High', 'Crucial']
const severityColors = ['white', 'orange', 'red', 'darkred']


const AttachmentThumbnailComponent = (props) => {

    if(props.attachment.type.includes("image")) return <img className={props.className} src={props.attachment.url} alt={props.attachment.url} />;
    if (props.attachment.type.includes("pdf")) return <PDFIcon {...props} color={props.color ? props.color : "darkred"} />
    else return <></>;

}


export default function Issue({issue, goBack}) {

    const [newComment, setNewComment] = useState("");


    const handleNewComment = (e) => {
        e.preventDefault();

        if(!e.target["comment-content"].value) return window.alert("Please enter a comment");


        issue.comments.push({
            by: {
                name: "Me",
                role: "Manager"
            },
            date: new Date(),
            content: e.target["comment-content"].value
        })

        setNewComment("");

    }

    return (
        <div id="issue-details-top-container">


            <button id="issue-details-back-button" className="clickableOpacity" onClick={goBack}> <BackChevronIcon style={{width: "11", height: "11", color: "#606060"}} /> Back to issues</button>


            <div id="issue-details-top-banner">

                <div id="issue-details-severity" style={{backgroundColor: severityColors[issue.severity], border: !issue.severity ? "1px solid black" : null, color: !issue.severity ? "black" : "white"}}>
                    {severityName[issue.severity].toUpperCase()}
                </div>

                <div id="issue-details-title-container">
                    <p id="issue-details-date">{issue.date.toLocaleDateString("en-US",{weekday: "long", year: "numeric", month: "long", day: "numeric"})}</p>
                    <h1 id="issue-details-title">{issue.title}</h1>
                    <p id="issue-details-postedBy">Posted by <span id="poster-name">{issue.postedBy.name}</span></p>
                </div>
            </div>

            <div id="issue-content-container">

                <div id="issue-content-left">
                    <div id="issue-content-description" className="issue-content-section">
                        <h2>Description:</h2>
                        <p>{issue.description}</p>
                    </div>

                    <div id="issue-content-attachments" className="issue-content-section">
                        <h2>Attachments:</h2>
                        <div id="issue-content-attachments-list">

                            {issue.attachments.map((attachment, i) => (
                                <div key={"attachment" + i} className="issue-content-attachment clickableBrightness" onClick={() => window.open(attachment.url, "_blank")}>
                                    
                                    <AttachmentThumbnailComponent className="issue-content-attachment-image" attachment={attachment} />

                                    <p className="issue-content-attachment-name">
                                        <span>{attachment.name}</span>
                                    </p>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>

                <div id="issue-content-right">
                    <div id="issue-content-comments" className="issue-content-section">
                        <h2>Comments:</h2>
                        <ul id="issue-content-comments-list">

                            {issue.comments.map((comment, i) => (
                                <li key={"comment" + i} className="issue-comment">
                                    <div className="issue-comment-info">
                                        <p className="issue-comment-by"><span>By: <span>{comment.by.name}</span></span></p>
                                        <p className="issue-comment-date"><span>{formatDistanceToNow(comment.date)} ago</span></p>
                                    </div>

                                    <p className="issue-comment-content"><span>{comment.content}</span></p>
                                </li>
                            ))}

                            <div className="issue-comment" id="issue-comment-new">
                                <div className="issue-comment-info">
                                    <p className="issue-comment-by"><span>By: <span>Me</span></span></p>
                                    <p className="issue-comment-date"><span>New comment</span></p>
                                </div>

                                <form onSubmit={handleNewComment}>

                                    <p className="issue-comment-content">
                                        <span>
                                            <textarea name="comment-content" id="new-comment-text" placeholder="Post a new comment" value={newComment} onChange={(e) => setNewComment(e.target.value)} required></textarea>
                                        </span>
                                    </p>

                                    <button type="submit" className="clickableOpacity" id="issue-comment-new-submit">Post</button>

                                </form>

                            </div>
                            

                        </ul>
                    </div>
                </div>

            </div>
        </div>
    );

}
import "./LatestUpdates.css";

import updateLine from "../../../../../../img/Updateline.png";

export default function LatestUpdates() {

    const latestUpdates = [

        {
            description: "Arrived at Istanbul Anchor",
            date: new Date("2022-11-19T08:45:00")
        },
        {
            description: "Arrived at Canakkale Anchor",
            date: new Date("2022-11-18T10:56:00")
        },
        {
            description: "Crossed Suez Canal",
            date: new Date("2022-11-14T13:03:00")
        },
        {
            description: "Crossed Malacca Strait",
            date: new Date("2022-11-12T12:33:00")
        },
        {
            description: "Exited Hong Kong Port",
            date: new Date("2022-11-11T09:00:00")
        },
    ]

    return (
        <div id="latest-updates-top-container">
            <h1 id="latest-updates-title">Latest Updates:</h1>

            <div id="update-line" style={{"backgroundImage" : `url(${updateLine})`}}></div>


            <div id="latest-updates-container">

                {latestUpdates.slice(0,3).map((update, index) => (
                    <div className="update-point" key={index}>
                        <svg className="update-point-vector" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="6" cy="6" r="6" fill="#565656"/>
                        </svg>

                        <div className="description-container">
                            <p className="update-description">{update.description}</p>
                            <p className="update-date">{update.date.toLocaleString('en-US', {weekday: "short", month: "short", year: "numeric", day: "numeric", hour: "numeric", minute: "2-digit"})}</p>
                        </div>

                    </div>
                ))}

                <div className="update-point">
                    <svg className="update-point-vector" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="6" cy="6" r="6" fill="#565656"/>
                    </svg>

                    <p className="update-description">See all updates </p>
                    <svg id="see-all-arrow" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.25004 9.41666C0.0972626 9.26388 0.020874 9.06943 0.020874 8.83332C0.020874 8.59721 0.0972626 8.40277 0.25004 8.24999L3.50004 4.99999L0.25004 1.74999C0.0972626 1.59721 0.020874 1.40277 0.020874 1.16666C0.020874 0.930545 0.0972626 0.736101 0.25004 0.583323C0.402818 0.430545 0.597262 0.354156 0.833374 0.354156C1.06948 0.354156 1.26393 0.430545 1.41671 0.583323L5.25004 4.41666C5.33337 4.49999 5.39254 4.59027 5.42754 4.68749C5.46199 4.78471 5.47921 4.88888 5.47921 4.99999C5.47921 5.1111 5.46199 5.21527 5.42754 5.31249C5.39254 5.40971 5.33337 5.49999 5.25004 5.58332L1.41671 9.41666C1.26393 9.56943 1.06948 9.64582 0.833374 9.64582C0.597262 9.64582 0.402818 9.56943 0.25004 9.41666Z" fill="black"/>
                    </svg>

                </div>
                
                {latestUpdates.reverse().slice(0,2).map((update, index) => (
                    <div className="update-point" key={index}>
                        <svg className="update-point-vector" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="6" cy="6" r="6" fill="#565656"/>
                        </svg>

                        <div className="description-container">
                            <p className="update-description">{update.description}</p>
                            <p className="update-date">{update.date.toLocaleString('en-US', {weekday: "short", month: "short", year: "numeric", day: "numeric", hour: "numeric", minute: "2-digit"})}</p>
                        </div>

                    </div>
                ))}

            </div>

        </div>
    );

}
import "./ChatButton.css";

export default function ChatButton() {

    return (
        <div className="chat-button">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.5 19.5C28.5 20.2956 28.1839 21.0587 27.6213 21.6213C27.0587 22.1839 26.2956 22.5 25.5 22.5H7.5L1.5 28.5V4.5C1.5 3.70435 1.81607 2.94129 2.37868 2.37868C2.94129 1.81607 3.70435 1.5 4.5 1.5H25.5C26.2956 1.5 27.0587 1.81607 27.6213 2.37868C28.1839 2.94129 28.5 3.70435 28.5 4.5V19.5Z" stroke="#D7D7D7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </div>
    );
}
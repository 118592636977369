import "./CrewList.css";

import NavBar from "../../../../Components/NavBar/NavBar";
import CrewListSearchBar from "./Components/CrewListSearchBar/CrewListSearchBar";

import PersonCard from "./Components/PersonCard/PersonCard.jsx";

// const roles = ["Captain", "Officer", "Cadet", "Engineer"];

const crew = [

    {
        name: "John Doe",
        role: "Captain",
        email: "johndoe@email.com"
    },
    {
        name: "Jane Doe",
        role: "Chief Engineer",
        email: "JaneDoeChief@email.com",
    }

]

export default function CrewList({ship, setLoggedIn}) {

    return (
        <div id="crewList-container">
            <NavBar subDirectory={[{text: ship.name, href:".."}, {text:"Crew List", href: "."}]} setLoggedIn={setLoggedIn} />
            <div className="nav-replacer" />

            <div id="crewList-card">

                <CrewListSearchBar placeholder="Search by keyword" />

                <div id="crewList-card-list-container">

                    {crew.map((person,index) => (

                        <PersonCard key={"person"+index} person={person} /> 

                    ))}

                </div>

            </div>

        </div>
    );

}
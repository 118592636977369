export default function BackChevronIcon({className, style}) {
	return (
		<svg className={className} style={style} width={style ? style.width : "18"} height={style ? style.height : "18"} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.7733 1.85175C12.8256 1.904 12.8672 1.96607 12.8956 2.03441C12.9239 2.10275 12.9385 2.17601 12.9385 2.25C12.9385 2.32399 12.9239 2.39725 12.8956 2.46559C12.8672 2.53393 12.8256 2.596 12.7733 2.64825L6.42037 9L12.7733 15.3517C12.8789 15.4574 12.9382 15.6006 12.9382 15.75C12.9382 15.8994 12.8789 16.0426 12.7733 16.1482C12.6676 16.2539 12.5244 16.3132 12.375 16.3132C12.2256 16.3132 12.0824 16.2539 11.9768 16.1482L5.22675 9.39825C5.17437 9.346 5.13281 9.28392 5.10445 9.21559C5.07609 9.14725 5.06149 9.07399 5.06149 9C5.06149 8.92601 5.07609 8.85275 5.10445 8.78441C5.13281 8.71607 5.17437 8.654 5.22675 8.60175L11.9768 1.85175C12.029 1.79937 12.0911 1.75781 12.1594 1.72945C12.2278 1.70109 12.301 1.68649 12.375 1.68649C12.449 1.68649 12.5222 1.70109 12.5906 1.72945C12.6589 1.75781 12.721 1.79937 12.7733 1.85175Z"
				fill={style ? style.color : "white"}
			/>
		</svg>
	);
}

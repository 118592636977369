import "./Issues.css";

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import NavBar from "../../../../Components/NavBar/NavBar.jsx";
import IssuesSearchBar from "./Components/IssuesSearchBar/IssuesSearchBar.jsx";
import IssueCard from "./Components/IssueCard/IssueCard.jsx";

import Issue from "./Pages/Issue/Issue.jsx";

const issuesList = [{
	id: "hBdsahj-6Gdsva",
	title: "AIS Devices Keeps Rebooting",
	vessel: "Titanic II",
	date: new Date("2022-12-25T10:00:00"),
	postedBy: { name: "Ahmad Taraballi", role: "Captain" },
	severity: 3,
	description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, incidunt vero id quas nam dolorum! Quidem numquam, error quam dicta eligendi dolorum sunt consequatur modi repellat ullam earum vel architecto.",
	attachments: [
		{
			name: "Image1.png",
			type: "image/png",
			url: "https://cruisingodyssey.com/wp-content/uploads/2019/09/FurunoAISa.jpg.png"
		},
		{
			name: "Cert.pdf",
			type: "application/pdf",
			url: "https://theswissbay.ch/pdf/Gentoomen%20Library/Maths/Sol%20Discrete%20and%20Combinatorial%20Mathematics%205ed%20R.%20Grimaldi%20Part%201%20BZUPAGES.COM.pdf"
		}
	],
	comments: [

		{
			by: { name: "Ahmad Taraballi", role: "Captain" },
			date: new Date("2023-02-09T10:00:00"),
			content: "The issue is still persistent, any updates on the technician's progress?"
		}

	]
}];

export default function Issues({ setLoggedIn, ship }) {
	const [, setFilterObj] = useState({});

	const [selectedIssue, setSelectedIssue] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {

		if(searchParams.has("issue")) {

			if (!issuesList.some(issue => issue.id === searchParams.get("issue"))) {
				setSelectedIssue(null);
				setSearchParams(prev => prev.delete("issue"));
				return;
			}
	
			setSelectedIssue(issuesList.find(issue => issue.id === searchParams.get("issue")))
	
		}

	}, [searchParams, setSearchParams]);

	useEffect(() => {

		if(!ship || !ship.name) return;

		document.title = "Issues - " + ship.name;

		return () => document.title = "ShipManager";

	})

	const handleViewIssue = (issue) => {

		if(!issue) return;

		setSearchParams({issue: issue.id})

	}

	return (
		<div id="issuesPanel-container">
			<NavBar
				subDirectory={[
					{ text: `${ship.name}`, href: ".." },
					{ text: "Issues", href: "." },
				]}
				setLoggedIn={setLoggedIn}
			/>
			<div className="nav-replacer" />
			
			{!searchParams.has("issue") && (
				<div id="issues-card">
					<IssuesSearchBar setOutsideFilterObj={setFilterObj} />

					<div id="issuesList-container">
						{issuesList.map((issue, i) => (
							<IssueCard key={"issue" + i} issue={issue} view={() => handleViewIssue(issue)} />
						))}
					</div>
				</div>
			)}
			{searchParams.has("issue") && selectedIssue && <Issue issue={selectedIssue} goBack={() => setSearchParams(prev => prev.delete("issue"))} />}

		</div>
	);
}

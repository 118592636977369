import "./SharedFilesSearchBar.css"

import { useState } from "react";

import UploadButton from "../UploadButton/UploadButton.jsx";


export default function IssuesSearchBar({setOutsideFilterObj, placeholder}) {

    const [searchText, setSearchText] = useState("");


    return (
        <div id="shared-files-search-bar">
            
            <div id="shared-files-searchBar-wrapper">
                <input type="text" name="shared-files-search" id="shared-files-search" placeholder={placeholder} value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            </div>
            <UploadButton onClick={() => console.log("Upload")} />

        </div>
    );

}
import "./FileButton.css";

import fileIconsProvider from "../../../../../../../../img/SVGComponents/FileIconsProvider";

const fileColors = [

    {
        type: "img",
        color: "#60C0C0"
    },

    {
        type: "pdf",
        color: "#CC4141",
    }
]


export default function FileButton({file, onClick, className}) {

    const FileIcon = fileIconsProvider[file.type.split(":")[1]]

    return (
        <button className={"fileButton-top-container clickableOpacity " + className} style={{backgroundColor: fileColors.filter(obj => file.type.includes(obj.type))[0].color}} onClick={onClick}>
            <FileIcon className="file-icon" />
            <p className="fileButton-name">{file.name.length > 15 ? file.name.substring(0,8)+"..." + file.name.split(".")[file.name.split(".").length-1] : file.name}</p>
        </button>
    );

}
import './App.css';
import {Routes, Route, Navigate} from 'react-router-dom';


import Login from "./Pages/Login/Login.jsx";
import Main from "./Pages/Main/Main.jsx";
import ShipDashboard from './Pages/Main/Pages/ShipDashboard/ShipDashboard';
import Error404 from './Pages/Error/404/Error404.jsx';

import { useAuth } from './hooks/useAuth';

function App() {

  const {loggedIn} = useAuth()

  return (
    
    loggedIn ?
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/ship" element={<Navigate to="/" />} />
      <Route path="/ship/:imo/*" element={<ShipDashboard/>} />
      <Route path="*" element={<Error404 />} />
    </Routes>
    :
    <Routes>
      <Route path="*" element={<Login />} />
    </Routes>
    
  );
}

export default App;

import "./Gallery.css";

import NavBar from "../../../../Components/NavBar/NavBar.jsx";

const pics = [
{
    src: "https://photos.marinetraffic.com/ais/showphoto.aspx?photoid=4525925",
},
{
    src: "https://photos.marinetraffic.com/ais/showphoto.aspx?photoid=4266927",
},
{
    src: "https://photos.marinetraffic.com/ais/showphoto.aspx?photoid=4348621",
},
{
    src: "https://photos.marinetraffic.com/ais/showphoto.aspx?photoid=4165139",
},
{
    src: "https://photos.marinetraffic.com/ais/showphoto.aspx?photoid=4061223",
},
{
    src: "https://photos.marinetraffic.com/ais/showphoto.aspx?photoid=4061220",
},
{
    src: "https://photos.marinetraffic.com/ais/showphoto.aspx?photoid=4422837",
},
{
    src: "https://photos.marinetraffic.com/ais/showphoto.aspx?photoid=4519875",
}]


export default function Gallery({ship, setLoggedIn}){

    return (

        <div id="gallery-container">
            <NavBar subDirectory={[{text: ship.name, href: ".."}, {text: "Gallery", href: "."}]} setLoggedIn={setLoggedIn} />
            <div className="nav-replacer" />

            <div id="gallery-photos-container">

                {pics.map((pic, index) => (
                    <img key={"ship-pic"+index} onClick={() => window.open(pic.src, "_blank")} className="gallery-image clickableBrightness" alt={pic.src} src={pic.src} />
                )
                )}

            </div>

        </div>

    )

}
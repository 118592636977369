import './NewShipCard.css';

import {MdAddCircleOutline} from 'react-icons/md';

export default function NewShipCard({ship}) {

    return (
        <div className="new-ship-card card-button">
            <MdAddCircleOutline className="new-ship-add-icon" fontSize={120}/>
        </div>
    );

}
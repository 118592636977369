import "./CrewListSearchBar.css"

import SearchButton from "../SearchButton/SearchButton.jsx";
import { useState } from "react";


export default function CrewListSearchBar({setOutsideFilterObj, placeholder}) {

    const [searchText, setSearchText] = useState("");


    return (
        <div id="crewlist-search-bar">
            
            <div id="crewlist-searchBar-wrapper">
                <input type="text" name="crewlist-search" id="crewlist-search" placeholder={placeholder} value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            </div>
            <SearchButton onClick={() => console.log("Search")} />

        </div>
    );

}
import "./IssuesSearchBar.css"

import FilterItem from "./Components/FilterItem/FilterItem.jsx";
import { useState } from "react";

export default function IssuesSearchBar({setOutsideFilterObj}) {

    const [searchText, setSearchText] = useState("");
    const [year, setYear] = useState(null);
    const [month, setMonth] = useState(null);
    const [day, setDay] = useState(null);
    const [type, setType] = useState(null)
    const [severity, setSeverity] = useState(null)

    const search = () => {

        const filterObj = {}

        if (searchText) filterObj.searchText = searchText;
        if (year) filterObj.year = year;
        if (month) filterObj.month = month;
        if (day) filterObj.day = day;
        if (type) filterObj.type = type;
        if(severity) filterObj.severity = severity;

        setOutsideFilterObj(filterObj);
        // console.log("Searched: ", filterObj)
    }

    return (
        <div id="issues-search-bar">
            
            <div id="searchBar-wrapper">

                <input type="text" name="issues-search" id="issues-search" placeholder="Search by title, description or name" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            </div>

            <div id="filterTools-wrapper">

                <p id="filter-by-text">Filter by: </p>

                <div className="filterTools-batch">
                    <FilterItem value={year} setValue={setYear} key="Year" type="range" range={{min:1900,max: new Date().getFullYear() }} placeholder="Year" width="50" />
                    <FilterItem value={month} setValue={setMonth} key="Month" type="range" range={{min:1, max: 12}} placeholder="Month"  width="61" />
                    <FilterItem value={day} setValue={setDay} key="Day" type="range" range={{min: 1, max: 31}} placeholder="Day" width="47" />
                </div>

                <div className="filterTools-batch">
                    <FilterItem value={type} setValue={setType} key="Type" type="selector" placeholder="Type" items={["Electric", "Logistic", "Financial"]} width="69" />
                    <FilterItem value={severity} setValue={setSeverity} key="Severity" type="selector" placeholder="Severity" items={["Low", "Medium", "High", "Crucial"]} width="89" />
                </div>

                <div id="searchButton" className="clickableOpacity" onClick={search}>
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5625 12.25H12.8713L12.6263 12.0138C13.5134 10.9848 14.001 9.67111 14 8.3125C14 7.18762 13.6664 6.088 13.0415 5.1527C12.4165 4.21739 11.5283 3.48841 10.489 3.05794C9.44976 2.62746 8.30619 2.51483 7.20293 2.73429C6.09966 2.95374 5.08624 3.49542 4.29083 4.29083C3.49542 5.08624 2.95374 6.09966 2.73429 7.20293C2.51483 8.30619 2.62746 9.44976 3.05794 10.489C3.48841 11.5283 4.21739 12.4165 5.1527 13.0415C6.088 13.6664 7.18762 14 8.3125 14C9.72125 14 11.0163 13.4838 12.0138 12.6263L12.25 12.8713V13.5625L16.625 17.9288L17.9288 16.625L13.5625 12.25ZM8.3125 12.25C6.13375 12.25 4.375 10.4913 4.375 8.3125C4.375 6.13375 6.13375 4.375 8.3125 4.375C10.4913 4.375 12.25 6.13375 12.25 8.3125C12.25 10.4913 10.4913 12.25 8.3125 12.25Z" fill="white"/>
                    </svg>
                </div>

            </div>
        </div>
    );

}
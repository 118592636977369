import "./FilterItem.css";

import { useEffect, useState } from "react";

export default function FilterItem({setValue, placeholder, type, range, items, width}) {

    const [open, setOpen] = useState(false);
    const [text, setText] = useState(placeholder);

    useEffect(() => {
        setOpen(false);
        if(text !== placeholder) {
            document.getElementById(`${placeholder}-selector`).classList.add("filterItem-container-active");
            setValue(text);
        } else {
            document.getElementById(`${placeholder}-selector`).classList.remove("filterItem-container-active");
            setValue(null);
        }
    },[text, placeholder, setValue])

    return (

        <div id={`${placeholder}-selector`} className="filterItem-container" >

            <div className="filterItem-button" onClick={() => setOpen(!open)} style={{minWidth: `${width}px`}}>
                <p className="filterItem-button-text" style={{width: `${width}px`}}>{text}</p>
                {/* <div className="filterItem-reset-button">
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.2984 10.0141C10.3175 10.0326 10.3326 10.0547 10.3429 10.0791C10.3533 10.1035 10.3586 10.1298 10.3586 10.1563C10.3586 10.1828 10.3533 10.2091 10.3429 10.2335C10.3326 10.2579 10.3175 10.28 10.2984 10.2985C10.28 10.3175 10.2579 10.3327 10.2334 10.343C10.209 10.3533 10.1828 10.3587 10.1562 10.3587C10.1297 10.3587 10.1035 10.3533 10.079 10.343C10.0546 10.3327 10.0325 10.3175 10.014 10.2985L6.49998 6.7895L2.98592 10.2985C2.96746 10.3175 2.94536 10.3327 2.92093 10.343C2.89651 10.3533 2.87026 10.3587 2.84373 10.3587C2.81721 10.3587 2.79096 10.3533 2.76653 10.343C2.74211 10.3327 2.72001 10.3175 2.70154 10.2985C2.6825 10.28 2.66736 10.2579 2.65702 10.2335C2.64669 10.2091 2.64136 10.1828 2.64136 10.1563C2.64136 10.1298 2.64669 10.1035 2.65702 10.0791C2.66736 10.0547 2.6825 10.0326 2.70154 10.0141L6.21053 6.50005L2.70154 2.98599C2.67221 2.94687 2.65796 2.89848 2.66143 2.84971C2.6649 2.80094 2.68584 2.75505 2.72041 2.72048C2.75499 2.68591 2.80087 2.66496 2.84965 2.6615C2.89842 2.65803 2.9468 2.67227 2.98592 2.70161L6.49998 6.2106L10.014 2.70161C10.0315 2.67833 10.0538 2.65907 10.0793 2.64514C10.1049 2.63121 10.1331 2.62294 10.1621 2.62087C10.1912 2.61881 10.2203 2.62301 10.2476 2.63318C10.2748 2.64336 10.2996 2.65928 10.3202 2.67986C10.3408 2.70043 10.3567 2.72519 10.3668 2.75246C10.377 2.77972 10.3812 2.80886 10.3792 2.83789C10.3771 2.86691 10.3688 2.89516 10.3549 2.92071C10.341 2.94626 10.3217 2.96853 10.2984 2.98599L6.78944 6.50005L10.2984 10.0141Z" fill="#606060"/>
                    </svg>
                </div> */}
            </div>

           {open && <div className="filterItem-overlay" onClick={() => setOpen(!open)} />}

            {open && <div className="filterItem-dropdown">
                
                <div className="filterItem-dropdown-item" onClick={() => setText(placeholder)}>
                    <p>Any</p>
                </div>

                {
                    type === "range" ?
                    
                        new Array(range.max - range.min + 1).fill(0).map((_, i) => {
                            
                            return (<div key={"r"+i} className="filterItem-dropdown-item" onClick={() => setText(range.max - i)}>
                                    <p>{range.max - i}</p>
                            </div>);
                            
                        })
                     :
                    type === "selector" ?
                    
                        items.map((item, i) => {
                            return (
                                <div key={"s" + i} className="filterItem-dropdown-item" onClick={() => setText(item)}>
                                    <p>{item}</p>
                                </div>
                            )
                        })
                     :
                    <p> wtf </p>
                }

            </div>}

        </div>

    )

}
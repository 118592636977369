import "./SharedFiles.css";

import { useEffect } from "react";

import NavBar from "../../../../Components/NavBar/NavBar.jsx";

import SharedFilesSearchBar from "./Components/SharedFilesSearchBar/SharedFilesSearchBar.jsx";
import FolderButton from "./Components/FolderButton/FolderButton.jsx";
import FileButton from "./Components/FileButton/FileButton";

const sharedFiles = [
	{
		type: "folder",
		name: "Invoices",
	},
	{
		type: "folder",
		name: "Shipments",
	},
	{
		type: "folder",
		name: "New Folder ",
	},
	{
		type: "folder",
		name: "New Folder Copy Copy Copy",
	},
	{
		type: "file:pdf",
		name: "Survey.pdf",
	},
	{
		type: "file:pdf",
		name: "Cert_2022.pdf",
	},
	{
		type: "file:img",
		name: "Titanic_II_img_2_new.png",
	},
];

export default function SharedFiles({ ship }) {

    useEffect(() => {

        if(!ship || !ship.name) return;

        document.title = "Shared Files - "+ ship.name;

        return () => document.title = "ShipManager";

    })

	return (
		<div id="sharedFiles-container">
			<NavBar
				subDirectory={[
					{ text: `${ship.name}`, href: ".." },
					{ text: "Shared Files", href: "." },
				]}
			/>
			<div className="nav-replacer" />

			<div id="sharedFiles-card">
				<SharedFilesSearchBar placeholder={`Search ${ship.name}'s Files`} />

				<div id="sharedFiles-card-contents-wrapper">
					<div className="sharedFiles-card-section">
						<h2>Folders</h2>

						<div className="sharedFiles-card-list" id="sharedFiles-folders-list">
							{sharedFiles
								.filter(f => f.type === "folder")
								.map((folder, index) => (
									<FolderButton
										key={"folder" + index}
										name={folder.name}
										className="sharedFiles-folder-button sharedFiles-button-general"
									/>
								))}
						</div>
					</div>

					<div className="sharedFiles-card-section">
						<h2>Files</h2>
						<div className="sharedFiles-card-list" id="sharedFiles-files-list">
							{sharedFiles
								.filter(f => f.type !== "folder")
								.map((file, index) => (
									<FileButton
										key={"file" + index}
										file={file}
										className="sharedFiles-file-button sharedFiles-button-general"
									/>
								))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
